import React from 'react';
import {
  CustomFlowbiteTheme, DeepPartial, FlowbiteButtonTheme, Table,
} from 'flowbite-react';
import { Card as FlowBiteCard } from 'flowbite-react/lib/esm/components/Card/Card';
import { Button as FlowBiteButton } from 'flowbite-react/lib/esm/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useOidc, useOidcIdToken } from '@axa-fr/react-oidc';
import { Link } from 'react-router-dom';
import { CatalogRoute } from '../Routes';
import defaultOrgId from '../data-market/DefaultOrgId';

function PricingPlanCard({
  productId, productName, pricingPlan, isMini,
}: PricingPlanCardProps) {
  const { t } = useTranslation();
  const customTheme: CustomFlowbiteTheme['card'] = {
    root: {
      base: 'bg-white shadow-none rounded-t-lg',
      children: 'bg-white rounded-lg',
    },
  };
  const { login } = useOidc();
  const { idTokenPayload } = useOidcIdToken();

  const customButtonTheme: DeepPartial<FlowbiteButtonTheme> = {
    base: 'w-full',
  };

  function renderHeader() {
    if (pricingPlan && 'x-wecity' in pricingPlan) {
      const tier = pricingPlan['x-wecity']?.tier ?? 'bronze';

      if (tier === 'silver') {
        return (
          <div
            className="relative hidden pt-0 lg:inline-flex w-full h-20 bg-gray-500 justify-center items-center rounded-t-lg overflow-hidden"
          >
            <div
              className="absolute left-0 w-64 h-20 origin-center rotate-45 bg-white/20 justify-center items-start inline-flex"
            />
          </div>
        );
      } if (tier === 'gold') {
        return (
          <div
            className="relative hidden pt-0 lg:inline-flex w-full h-20 bg-yellow-500 justify-center items-center rounded-t-lg overflow-hidden"
          >
            <div
              className="absolute left-0 w-64 h-20 origin-center rotate-45 bg-white/30 justify-center items-start inline-flex"
            />
          </div>
        );
      }
    }

    return (
      <div
        className="relative hidden pt-0 lg:inline-flex w-full h-20 bg-yellow-800 justify-center items-center rounded-t-lg overflow-hidden"
      >
        <div
          className="absolute left-0 w-64 h-20 origin-center rotate-45 bg-white/20 justify-center items-start inline-flex"
        />
      </div>
    );
  }

  function createSubscribeUrl(): string {
    const orgId = defaultOrgId();
    const params = new URLSearchParams({
      firstname: idTokenPayload.firstname ?? '',
      lastname: idTokenPayload.lastname ?? '',
      email: idTokenPayload.email ?? '',
      subject: `Nieuwe aanvraag ${orgId ?? 'onbekend'}`,
      content: `Nieuwe aanvraag ${productName}`,
      dm_product_name: productName,
      dm_plan_name: pricingPlan?.name,
      wecity_organization_id: orgId ?? 'onbekend',
      dm_product_id: productId,
      dm_plan_id: pricingPlan['x-wecity'].planId,
    });

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      return `https://share-eu1.hsforms.com/14dCI1kmlTxa6-TR2xQELVg2borbx?${params.toString()}`;
    }

    return `https://share-eu1.hsforms.com/1r5YjUc1jRpKKOAMbhdEluw2dc0ht?${params.toString()}`;
  }

  function renderBody() {
    return (
      <div className="flex-col w-full justify-between items-center inline-flex rounded-lg">
        <div className="flex-col w-full items-center p-5 rounded-t-lg">
          <div className="flex-col w-full justify-start items-start inline-flex">
            <div className="text-gray-900 text-base lg:text-2xl font-bold inline-flex text-left w-full">
              {pricingPlan?.name}
            </div>

            <div className="w-full py-4">
              <Table className="w-full" align="left">
                <Table.Body className="overflow-x-hidden text-left w-full items-start">
                  <Table.Row className="border-b border-solid border-gray-200 items-start w-full">
                    <Table.Cell className="text-gray-400 p-1 px-2 inline-flex space-x-2 w-full">
                      <div className="text-gray-400">
                        Looptijd
                      </div>
                      <div className="text-gray-800 whitespace-nowrap">
                        Op aanvraag
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="border-b border-solid border-gray-200">
                    <Table.Cell className="p-1 px-2 inline-flex space-x-2 items-center">
                      <div className="text-gray-400">Factureringstermijn</div>
                      <div className="text-gray-800 whitespace-nowrap">
                        {t(pricingPlan?.billingDuration)}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="border-b border-solid border-gray-200">
                    <Table.Cell className="p-1 px-2 inline-flex space-x-2 items-center">
                      <div className="text-gray-800 whitespace-nowrap">
                        {pricingPlan?.maxTransactionQuantity}
                      </div>
                      <div className="text-gray-400">aanroepen per maand</div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            {idTokenPayload && (
              <div className="flex justify-center w-full text-2xl font-bold">

                <div>
                  &euro;
                  {' '}
                  {pricingPlan?.price}
                </div>

              </div>
            )}
            {
              !idTokenPayload && (
                <div className="flex justify-center w-full text-center py-5">
                  Log in om de prijs te zien
                </div>
              )
            }

            {!isMini && (
              <div className="flex justify-center w-full">
                {idTokenPayload ? (
                  <Link to={createSubscribeUrl()} target="_blank" className="w-full">
                    <FlowBiteButton size="sm" color="info" theme={customButtonTheme}>
                      <div className="w-full justify-center items-center gap-2 inline-flex">
                        <div className="text-sm font-medium w-full">
                          Abonneren (
                          {defaultOrgId() ?? ''}
                          )
                        </div>
                      </div>
                    </FlowBiteButton>
                  </Link>
                ) : (
                  <FlowBiteButton
                    size="sm"
                    color="info"
                    className="w-full"
                    onClick={() => login(CatalogRoute())}
                    theme={customButtonTheme}
                  >
                    <div className="w-full justify-center items-center gap-2 inline-flex">
                      <div className="text-sm font-medium w-full">Inloggen en abonneren</div>
                    </div>
                  </FlowBiteButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (isMini) {
    return (
      <FlowBiteCard
        theme={customTheme}
        className="lg:max-w-xs max-w-sm rounded-lg break-inside-avoid"
        renderImage={() => renderHeader()}
      >
        {renderBody()}
      </FlowBiteCard>
    );
  }

  return (
    <FlowBiteCard
      theme={customTheme}
      className="max-w-sm rounded-lg break-inside-avoid"
      renderImage={() => renderHeader()}
    >
      {renderBody()}
    </FlowBiteCard>
  );
}

type PricingPlanCardProps = {
  productId: string,
  productName: string,
  pricingPlan: any
  isMini?: boolean
};

export default PricingPlanCard;
