import React from 'react';
import { useOidc, useOidcIdToken } from '@axa-fr/react-oidc';
import { Button as FlowBiteButton } from 'flowbite-react/lib/esm/components/Button/Button';
import { LandingRoute } from '../Routes';
import MySubscriptionsLink from './MySubscriptionsLink';

function Hero() {
  const { login } = useOidc();
  const { idTokenPayload } = useOidcIdToken();

  return (
    <div className="pt-2 lg:py-10 text-center flex-col items-center space-y-6 bg-white">
      <div className="mx-auto max-w-screen-xl flex-col">
        <h1 className="text-3xl lg:text-5xl font-extrabold text-gray-900 leading-none lg:leading-10 pb-2">
          Data Catalogus
        </h1>

        {idTokenPayload ? (
          <div className="leading-tight text-sm lg:text-xl text-gray-500 sm:px-16 xl:px-32">
            Zoek in het aanbod van dataproducten van verschillende aanbieders, bekijk de details en neem een abonnement.
            U kunt uw huidige abonnementen zien bij
            {' '}
            <MySubscriptionsLink />
          </div>
        ) : (
          <div className="leading-tight text-sm lg:text-xl text-gray-500 sm:px-16 xl:px-32">
            Zoek in het aanbod van dataproducten van verschillende aanbieders en lees in praktijkvoorbeelden hoe dataproducten worden toegepast.
            DMI deelnemers kunnen inloggen om producten te testen en abonnementen te kopen.
          </div>
        )}

        {!idTokenPayload && (
          <div className="flex items-center justify-center gap-4 py-4">
            <FlowBiteButton size="xl" color="info" onClick={() => login(LandingRoute())}>
              <div className="w-full justify-center items-center gap-2 inline-flex">
                Inloggen
              </div>
            </FlowBiteButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default Hero;
