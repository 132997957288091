import { UUID } from 'node:crypto';
import Ajv from 'ajv';
import { $Validator, FromSchema, wrapValidatorAsTypeGuard } from 'json-schema-to-ts';
import MersenneTwister from 'mersenne-twister';
import odpsSchema from './odps.json';
import { OfferingsLocalStorageKey } from './Global';

// Setup ajv json schema validator
const ajv = new Ajv({ allErrors: true });
const $validate: $Validator = (schema, unknownData) => ajv.validate(schema, unknownData);
const validateAsTypeGuard = wrapValidatorAsTypeGuard($validate);

export type ODPS = FromSchema<typeof odpsSchema>;

const cardColors = ['#ACC7D8', '#A2BFD0', '#90ADC1', '#708DA3', '#607F94', '#95BBD3', '#8BB3CB', '#79A1BB', '#59819D', '#49738E', '#90B0C5', '#86A7BD', '#7496AD', '#54768F', '#446880', '#8BA6B7', '#819DAF', '#6F8C9F', '#4E6C81', '#3E5D73'];
const mt = new MersenneTwister();

export function randomColorWithSeed(seed: number) {
  mt.init_seed(seed);
  return cardColors[Math.floor(mt.random() * (cardColors.length - 1))];
}

export function syncOfferWithLocalStorage(localProductId: string, backendProductId: string) {
  let offers: Offer[] = [];
  if (localStorage.getItem(OfferingsLocalStorageKey)) {
    offers = (JSON.parse(localStorage.getItem(OfferingsLocalStorageKey) ?? '[]') ?? []) as Offer[];
  }

  if (!offers.find((o) => o.id === localProductId)) {
    offers = [...offers, { id: localProductId, savedToBackend: false } as Offer];
    localStorage.setItem(OfferingsLocalStorageKey, JSON.stringify(offers));
  }

  if (backendProductId === localProductId) {
    localStorage.setItem(OfferingsLocalStorageKey, JSON.stringify(offers.map((o: Offer) => ((o.id === localProductId) ? ({ ...o, savedToBackend: true } as Offer) : o))));
  }
}

export type Offer = {
  id: UUID
  isWebservice?: boolean,
  dataProductType?: String,
  hasConfirmedOrganization?: boolean,
  language?: String,
  savedToBackend?: boolean
};

/**
 * @throws {Error | TypeError}
 */
export function validateOdpsWithExceptions(rawOdps: any): ODPS | undefined {
  const parsedOdps = JSON.parse(rawOdps);

  if (parsedOdps) {
    if (validateAsTypeGuard(odpsSchema, parsedOdps)) {
      return parsedOdps;
    }
    if (ajv.errors) {
      throw new Error(ajv.errors.map((e) => `${e.message} at ${e.schemaPath}`).join(', '));
    }
  }

  return undefined;
}

export default function validateOdps(rawOdps: any): ODPS | undefined {
  try {
    const parsedOdps = JSON.parse(rawOdps);

    if (parsedOdps) {
      if (validateAsTypeGuard(odpsSchema, parsedOdps)) {
        return parsedOdps;
      }
    }
  } catch (error) { /* empty */ }

  return undefined;
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export type OdpsValidationResult = {
  success: boolean
  errorMessage?: string
  validOdps?: ODPS
};
