import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Base64 string */
  Base64: { input: any; output: any; }
  /** A date time with timezone formatted according to RFC3339 (without millis, we're not timing F1 here) */
  DateTime: { input: any; output: any; }
  /** A date time with timezone formatted according to RFC3339 (without millis, we're not timing F1 here) */
  Instant: { input: any; output: any; }
  /** Valid JSON */
  Json: { input: any; output: any; }
};

export type Api = {
  __typename?: 'Api';
  name: ApiName;
  plans: Array<ApiPlan>;
};

export type ApiCatalog = {
  __typename?: 'ApiCatalog';
  apis: Array<Api>;
  /** Subscriptions of the current user */
  userSubscriptions: Array<ApiSubscriptionUser>;
};

export type ApiCatalogMeteringMembers = {
  __typename?: 'ApiCatalogMeteringMembers';
  api?: Maybe<Scalars['String']['output']>;
  day?: Maybe<TimeDimension>;
  key_id?: Maybe<Scalars['String']['output']>;
  sum_bytes?: Maybe<Scalars['Float']['output']>;
  sum_calls?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type ApiCatalogMeteringOrderByInput = {
  api?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  key_id?: InputMaybe<OrderBy>;
  sum_bytes?: InputMaybe<OrderBy>;
  sum_calls?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

export type ApiCatalogMeteringWhereInput = {
  AND?: InputMaybe<Array<ApiCatalogMeteringWhereInput>>;
  OR?: InputMaybe<Array<ApiCatalogMeteringWhereInput>>;
  api?: InputMaybe<StringFilter>;
  day?: InputMaybe<DateTimeFilter>;
  key_id?: InputMaybe<StringFilter>;
  sum_bytes?: InputMaybe<FloatFilter>;
  sum_calls?: InputMaybe<FloatFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type ApiCatalogMutation = {
  __typename?: 'ApiCatalogMutation';
  createUserApiKey: Array<ApiKey>;
  createUserSubscription: ApiSubscriptionUser;
  deleteUserApiKey: Scalars['ID']['output'];
  deleteUserSubscription: Scalars['ID']['output'];
};


export type ApiCatalogMutationCreateUserApiKeyArgs = {
  subscriptionId: Scalars['ID']['input'];
};


export type ApiCatalogMutationCreateUserSubscriptionArgs = {
  command: CreateUserApiSubscriptionCommand;
};


export type ApiCatalogMutationDeleteUserApiKeyArgs = {
  id: Scalars['ID']['input'];
};


export type ApiCatalogMutationDeleteUserSubscriptionArgs = {
  id: Scalars['ID']['input'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
};

export enum ApiName {
  MijnPientereTuin = 'MIJN_PIENTERE_TUIN'
}

export type ApiPlan = {
  __typename?: 'ApiPlan';
  slug: Scalars['String']['output'];
};

export type ApiSubscriptionUser = {
  __typename?: 'ApiSubscriptionUser';
  api: Api;
  createdAt: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  keys: Array<ApiKey>;
  plan: ApiPlan;
  updatedAt: Scalars['Instant']['output'];
};

export type BulkOrganizationRolesCommand = {
  orgId: Scalars['ID']['input'];
  roles: Array<RoleOrganization>;
  userIds: Array<Scalars['ID']['input']>;
};

export type BulkUserRolesCommand = {
  roles: Array<RoleUser>;
  userIds: Array<Scalars['ID']['input']>;
};

export type CreateAdminProductCommand = {
  active: Scalars['Boolean']['input'];
  dataType: DataProductDataType;
  id?: InputMaybe<Scalars['ID']['input']>;
  openDataProductSpecification: Scalars['Json']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateDataProductPlanSubscription = {
  active: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
  purpose: Scalars['String']['input'];
};

export type CreateDataProductPlanSubscriptionApiKey = {
  id?: InputMaybe<Scalars['ID']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type CreateOrganizationCommand = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  /** Slug of the organization, only a-z, 0-9, `-` */
  orgId: Scalars['ID']['input'];
};

export type CreateSensorRegistrationTokensCommand = {
  listId: Scalars['ID']['input'];
  /** The orgId of the organization the registered sensors should belong to */
  orgId: Scalars['ID']['input'];
};

export type CreateSensorRegistrationTokensResult = {
  __typename?: 'CreateSensorRegistrationTokensResult';
  createdTokensForUsers: Array<User>;
  errorMessages: Array<Scalars['String']['output']>;
};

export type CreateTreeCommand = {
  crownDiameterClass: HeightInMeterClass;
  heightClass: HeightInMeterClass;
  inputSpecies: InputSpecies;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type CreateUserApiSubscriptionCommand = {
  api: ApiName;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Slug of the ApiPlan */
  plan: Scalars['String']['input'];
};

export type CreateUserCommand = {
  coc: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastname: Scalars['String']['input'];
};

export type DataMarket = {
  __typename?: 'DataMarket';
  adminProduct: DataProduct;
  adminProducts: Array<DataProduct>;
  adminSubscriptions?: Maybe<Array<DataProductPlanSubscription>>;
  products: Array<DataProduct>;
  subscriptions?: Maybe<Array<DataProductPlanSubscription>>;
  userProduct: DataProduct;
  userProducts: Array<DataProduct>;
};


export type DataMarketAdminProductArgs = {
  id: Scalars['ID']['input'];
};


export type DataMarketUserProductArgs = {
  id: Scalars['ID']['input'];
};

export type DataMarketMeteringMembers = {
  __typename?: 'DataMarketMeteringMembers';
  day?: Maybe<TimeDimension>;
  key_id?: Maybe<Scalars['String']['output']>;
  obscured_key?: Maybe<Scalars['String']['output']>;
  org_id?: Maybe<Scalars['String']['output']>;
  plan_id?: Maybe<Scalars['String']['output']>;
  sum_bytes?: Maybe<Scalars['Float']['output']>;
  sum_calls?: Maybe<Scalars['Float']['output']>;
};

export type DataMarketMeteringOrderByInput = {
  day?: InputMaybe<OrderBy>;
  key_id?: InputMaybe<OrderBy>;
  obscured_key?: InputMaybe<OrderBy>;
  org_id?: InputMaybe<OrderBy>;
  plan_id?: InputMaybe<OrderBy>;
  sum_bytes?: InputMaybe<OrderBy>;
  sum_calls?: InputMaybe<OrderBy>;
};

export type DataMarketMeteringWhereInput = {
  AND?: InputMaybe<Array<DataMarketMeteringWhereInput>>;
  OR?: InputMaybe<Array<DataMarketMeteringWhereInput>>;
  day?: InputMaybe<DateTimeFilter>;
  key_id?: InputMaybe<StringFilter>;
  obscured_key?: InputMaybe<StringFilter>;
  org_id?: InputMaybe<StringFilter>;
  plan_id?: InputMaybe<StringFilter>;
  sum_bytes?: InputMaybe<FloatFilter>;
  sum_calls?: InputMaybe<FloatFilter>;
};

export type DataMarketMutation = {
  __typename?: 'DataMarketMutation';
  adminAcceptProduct: Scalars['ID']['output'];
  adminCreateApiKey: DataProductPlanSubscription;
  adminCreateProduct: DataProduct;
  adminCreateSubscription: DataProductPlanSubscription;
  adminDeleteApiKey: DataProductPlanSubscription;
  adminDeleteHttpSource: Scalars['ID']['output'];
  adminDeleteProduct: Scalars['ID']['output'];
  adminDeleteSubscription: Scalars['ID']['output'];
  adminRequestAmendmentProduct: Scalars['ID']['output'];
  adminSetHttpSource: DataProductHttpSource;
  adminUpdateProduct: DataProduct;
  adminUpdateSubscription: DataProductPlanSubscription;
  createApiKey: DataProductPlanSubscription;
  deleteApiKey: DataProductPlanSubscription;
  deleteSubscription: Scalars['ID']['output'];
  pushProductToHubspot?: Maybe<Scalars['Boolean']['output']>;
  reprocessDataProduct?: Maybe<Scalars['Boolean']['output']>;
  reprocessDataProducts?: Maybe<Scalars['Boolean']['output']>;
  userDeleteProduct: Scalars['ID']['output'];
  userSubmitProduct: Scalars['ID']['output'];
  userUpsertProduct: DataProduct;
};


export type DataMarketMutationAdminAcceptProductArgs = {
  id: Scalars['ID']['input'];
};


export type DataMarketMutationAdminCreateApiKeyArgs = {
  command: CreateDataProductPlanSubscriptionApiKey;
};


export type DataMarketMutationAdminCreateProductArgs = {
  command: CreateAdminProductCommand;
};


export type DataMarketMutationAdminCreateSubscriptionArgs = {
  command: CreateDataProductPlanSubscription;
};


export type DataMarketMutationAdminDeleteApiKeyArgs = {
  keyId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};


export type DataMarketMutationAdminDeleteHttpSourceArgs = {
  dataProductId: Scalars['ID']['input'];
};


export type DataMarketMutationAdminDeleteProductArgs = {
  id: Scalars['ID']['input'];
};


export type DataMarketMutationAdminDeleteSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type DataMarketMutationAdminRequestAmendmentProductArgs = {
  id: Scalars['ID']['input'];
};


export type DataMarketMutationAdminSetHttpSourceArgs = {
  command: SetHttpDataSourceCommand;
};


export type DataMarketMutationAdminUpdateProductArgs = {
  command: CreateAdminProductCommand;
};


export type DataMarketMutationAdminUpdateSubscriptionArgs = {
  command: UpdateDataProductPlanSubscription;
};


export type DataMarketMutationCreateApiKeyArgs = {
  command: CreateDataProductPlanSubscriptionApiKey;
};


export type DataMarketMutationDeleteApiKeyArgs = {
  keyId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};


export type DataMarketMutationDeleteSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type DataMarketMutationPushProductToHubspotArgs = {
  id: Scalars['ID']['input'];
};


export type DataMarketMutationReprocessDataProductArgs = {
  id: Scalars['ID']['input'];
};


export type DataMarketMutationUserDeleteProductArgs = {
  id: Scalars['ID']['input'];
};


export type DataMarketMutationUserSubmitProductArgs = {
  id: Scalars['ID']['input'];
};


export type DataMarketMutationUserUpsertProductArgs = {
  command: UpsertUserProductCommand;
};

export type DataProduct = {
  __typename?: 'DataProduct';
  active: Scalars['Boolean']['output'];
  dataType: DataProductDataType;
  description: Scalars['String']['output'];
  httpSource?: Maybe<DataProductHttpSource>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  openDataProductSpecification: Scalars['Json']['output'];
  state: Scalars['String']['output'];
  supplier: Scalars['String']['output'];
};

export enum DataProductDataType {
  Common = 'COMMON',
  Personal = 'PERSONAL'
}

export type DataProductFilter = {
  __typename?: 'DataProductFilter';
  locations: Array<DataProductLocation>;
  suppliers: Array<Scalars['String']['output']>;
  themes: Array<DataProductTheme>;
};

export type DataProductHttpSource = {
  __typename?: 'DataProductHttpSource';
  basePath: Scalars['String']['output'];
  forwardedHeaders: Array<ForwardedHeader>;
  forwardedHostname?: Maybe<Scalars['String']['output']>;
  host: Scalars['String']['output'];
  port: Scalars['Int']['output'];
  scheme: HttpScheme;
};

export type DataProductLocation = {
  __typename?: 'DataProductLocation';
  name: Scalars['String']['output'];
  type: DataProductLocationType;
};

export enum DataProductLocationType {
  City = 'CITY',
  Other = 'OTHER',
  Province = 'PROVINCE'
}

export type DataProductPlan = {
  __typename?: 'DataProductPlan';
  id: Scalars['ID']['output'];
};

export type DataProductPlanSubscription = {
  __typename?: 'DataProductPlanSubscription';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  keys: Array<DataProductPlanSubscriptionApiKey>;
  planId: Scalars['ID']['output'];
  product: DataProduct;
  purpose: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
};

export type DataProductPlanSubscriptionApiKey = {
  __typename?: 'DataProductPlanSubscriptionApiKey';
  createdAt: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
};

export type DataProductSearchQuery = {
  /** String to search for in title en description Minimum 3 characters */
  searchString: Scalars['String']['input'];
};

export type DataProductTheme = {
  __typename?: 'DataProductTheme';
  name: Scalars['String']['output'];
  type: DataProductThemeType;
};

export enum DataProductThemeType {
  Dmi = 'DMI',
  Wecity = 'WECITY'
}

export type DateTimeFilter = {
  afterDate?: InputMaybe<Scalars['String']['input']>;
  beforeDate?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notEquals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notInDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteTreeCommand = {
  id: Scalars['ID']['input'];
  reason: DeleteTreeReason;
};

export enum DeleteTreeReason {
  DoesNotExist = 'DOES_NOT_EXIST',
  Fallen = 'FALLEN',
  Felled = 'FELLED',
  Unknown = 'UNKNOWN'
}

export type DmiOrganization = {
  __typename?: 'DmiOrganization';
  chamberOfCommerceNumber?: Maybe<Scalars['String']['output']>;
  dmiId: Scalars['String']['output'];
  eoriNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
};

export type DmiUser = {
  __typename?: 'DmiUser';
  dmiId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  notEquals?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ForwardedHeader = {
  __typename?: 'ForwardedHeader';
  header: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ForwardedHeaderCommand = {
  header: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum HeightInMeterClass {
  AboveThirty = 'ABOVE_THIRTY',
  EighteenToTwentyOne = 'EIGHTEEN_TO_TWENTY_ONE',
  FifteenToEighteen = 'FIFTEEN_TO_EIGHTEEN',
  NineToTwelve = 'NINE_TO_TWELVE',
  SixToNine = 'SIX_TO_NINE',
  ThreeToSix = 'THREE_TO_SIX',
  TwelveToFifteen = 'TWELVE_TO_FIFTEEN',
  TwentyFourToTwentySeven = 'TWENTY_FOUR_TO_TWENTY_SEVEN',
  TwentyOneToTwentyFour = 'TWENTY_ONE_TO_TWENTY_FOUR',
  TwentySevenToThirty = 'TWENTY_SEVEN_TO_THIRTY',
  ZeroToThree = 'ZERO_TO_THREE'
}

export enum HttpScheme {
  Http = 'HTTP',
  Https = 'HTTPS'
}

export type HubspotContactList = {
  __typename?: 'HubspotContactList';
  contactCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type HubspotMutation = {
  __typename?: 'HubspotMutation';
  /** Create sensor registration tokens for the users found in the list */
  createSensorRegistrationTokens: CreateSensorRegistrationTokensResult;
  /** Imports all the contacts from a Hubspot list as users into the given organization */
  importList: ImportHubspotListResult;
  /** Resends verification emails to all contacts in the hubspot contact list (if they exist as user) and returns a list of success/failure messages */
  sendVerificationEmails: Array<Scalars['String']['output']>;
};


export type HubspotMutationCreateSensorRegistrationTokensArgs = {
  command: CreateSensorRegistrationTokensCommand;
};


export type HubspotMutationImportListArgs = {
  command: ImportHubspotListCommand;
};


export type HubspotMutationSendVerificationEmailsArgs = {
  hubspotContactListId: Scalars['ID']['input'];
};

export type HubspotQuery = {
  __typename?: 'HubspotQuery';
  contactLists: Array<HubspotContactList>;
};

export type HumidityMeasurement = {
  __typename?: 'HumidityMeasurement';
  dateTime: Scalars['Instant']['output'];
  /** Percentage of humidity expressed as float between 0 and 1. No measurement is expressed as null */
  value?: Maybe<Scalars['Float']['output']>;
};

export type ImportHubspotListCommand = {
  listId: Scalars['ID']['input'];
  /** The orgId of the organization the user belongs to initially */
  orgId: Scalars['ID']['input'];
  /** The roles the user will get in the organization with orgId. If the list is empty, this and orgId are ignored */
  organizationRoles: Array<RoleOrganization>;
  userRoles: Array<RoleUser>;
};

export type ImportHubspotListResult = {
  __typename?: 'ImportHubspotListResult';
  errorMessages: Array<Scalars['String']['output']>;
  importedUsers: Array<User>;
};

export type InputSpecies = {
  speciesCode?: InputMaybe<Scalars['String']['input']>;
  speciesFreeText?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  apiCatalog: ApiCatalogMutation;
  dataMarket: DataMarketMutation;
  hubspot: HubspotMutation;
  organizations: OrganizationsMutation;
  sensors: SensorsMutation;
  trees: TreesMutation;
  users: UsersMutation;
};

export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc'
}

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  users: Array<OrganizationUser>;
};

export type OrganizationUser = {
  __typename?: 'OrganizationUser';
  organization: Organization;
  organizationRoles: Array<RoleOrganization>;
  user: User;
};

export type OrganizationsMutation = {
  __typename?: 'OrganizationsMutation';
  createOrganization: Organization;
  promoteDmiOrganization: Organization;
  updateOrganization: Organization;
};


export type OrganizationsMutationCreateOrganizationArgs = {
  command: CreateOrganizationCommand;
};


export type OrganizationsMutationPromoteDmiOrganizationArgs = {
  command: PromoteDmiOrganizationCommand;
};


export type OrganizationsMutationUpdateOrganizationArgs = {
  command: UpdateOrganizationCommand;
};

export type PientereTuinenMeasurementsMembers = {
  __typename?: 'PientereTuinenMeasurementsMembers';
  avg_moisture_percentage?: Maybe<Scalars['Float']['output']>;
  avg_temperature_celsius?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  garden_hardening_percentage?: Maybe<Scalars['Float']['output']>;
  garden_orientation?: Maybe<Scalars['String']['output']>;
  garden_size?: Maybe<Scalars['String']['output']>;
  max_moisture_percentage?: Maybe<Scalars['Float']['output']>;
  max_temperature_celsius?: Maybe<Scalars['Float']['output']>;
  measured_at?: Maybe<TimeDimension>;
  min_moisture_percentage?: Maybe<Scalars['Float']['output']>;
  min_temperature_celsius?: Maybe<Scalars['Float']['output']>;
  moisture_percentage?: Maybe<Scalars['Float']['output']>;
  p05_moisture_percentage?: Maybe<Scalars['Float']['output']>;
  p05_temperature_celsius?: Maybe<Scalars['Float']['output']>;
  p95_moisture_percentage?: Maybe<Scalars['Float']['output']>;
  p95_temperature_celsius?: Maybe<Scalars['Float']['output']>;
  sensor_id?: Maybe<Scalars['String']['output']>;
  soil_type?: Maybe<Scalars['String']['output']>;
  temperature_celsius?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type PientereTuinenMeasurementsOrderByInput = {
  avg_moisture_percentage?: InputMaybe<OrderBy>;
  avg_temperature_celsius?: InputMaybe<OrderBy>;
  count?: InputMaybe<OrderBy>;
  garden_hardening_percentage?: InputMaybe<OrderBy>;
  garden_orientation?: InputMaybe<OrderBy>;
  garden_size?: InputMaybe<OrderBy>;
  max_moisture_percentage?: InputMaybe<OrderBy>;
  max_temperature_celsius?: InputMaybe<OrderBy>;
  measured_at?: InputMaybe<OrderBy>;
  min_moisture_percentage?: InputMaybe<OrderBy>;
  min_temperature_celsius?: InputMaybe<OrderBy>;
  moisture_percentage?: InputMaybe<OrderBy>;
  p05_moisture_percentage?: InputMaybe<OrderBy>;
  p05_temperature_celsius?: InputMaybe<OrderBy>;
  p95_moisture_percentage?: InputMaybe<OrderBy>;
  p95_temperature_celsius?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  soil_type?: InputMaybe<OrderBy>;
  temperature_celsius?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

export type PientereTuinenMeasurementsWhereInput = {
  AND?: InputMaybe<Array<PientereTuinenMeasurementsWhereInput>>;
  OR?: InputMaybe<Array<PientereTuinenMeasurementsWhereInput>>;
  avg_moisture_percentage?: InputMaybe<FloatFilter>;
  avg_temperature_celsius?: InputMaybe<FloatFilter>;
  count?: InputMaybe<FloatFilter>;
  garden_hardening_percentage?: InputMaybe<FloatFilter>;
  garden_orientation?: InputMaybe<StringFilter>;
  garden_size?: InputMaybe<StringFilter>;
  max_moisture_percentage?: InputMaybe<FloatFilter>;
  max_temperature_celsius?: InputMaybe<FloatFilter>;
  measured_at?: InputMaybe<DateTimeFilter>;
  min_moisture_percentage?: InputMaybe<FloatFilter>;
  min_temperature_celsius?: InputMaybe<FloatFilter>;
  moisture_percentage?: InputMaybe<FloatFilter>;
  p05_moisture_percentage?: InputMaybe<FloatFilter>;
  p05_temperature_celsius?: InputMaybe<FloatFilter>;
  p95_moisture_percentage?: InputMaybe<FloatFilter>;
  p95_temperature_celsius?: InputMaybe<FloatFilter>;
  sensor_id?: InputMaybe<StringFilter>;
  soil_type?: InputMaybe<StringFilter>;
  temperature_celsius?: InputMaybe<FloatFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type PlatformAlert = {
  __typename?: 'PlatformAlert';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PossibleHumidityMeasurement = HumidityMeasurement | SensorError;

export type PossibleTemperatureMeasurement = SensorError | TemperatureMeasurement;

export type PromoteDmiOrganizationCommand = {
  id: Scalars['ID']['input'];
  orgId: Scalars['String']['input'];
};

export type PromoteDmiUserCommand = {
  id: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  apiCatalog: ApiCatalog;
  cube: Array<Result>;
  dataMarket: DataMarket;
  dataProductFilter: DataProductFilter;
  dataProductSearch: Array<DataProduct>;
  hubspot: HubspotQuery;
  myOrganizationRoles: Array<OrganizationUser>;
  mySensorRegistrationToken?: Maybe<SensorRegistrationToken>;
  myUserRoles: Array<RoleUser>;
  organization: Organization;
  organizations: Array<Organization>;
  platformAlerts: Array<PlatformAlert>;
  promotableDmiOrganizations: Array<DmiOrganization>;
  promotableDmiUsers: Array<DmiUser>;
  sensoterraSensor?: Maybe<SensoterraSensor>;
  trees: Trees;
  user: User;
  userSearch: Array<UserSummary>;
};


export type QueryCubeArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RootOrderByInput>;
  renewQuery?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<RootWhereInput>;
};


export type QueryDataProductSearchArgs = {
  query: DataProductSearchQuery;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySensoterraSensorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserSearchArgs = {
  query: UserSearchQuery;
};

export type RegisterSensoterraSensorCommand = {
  id?: InputMaybe<Scalars['ID']['input']>;
  serial: Scalars['ID']['input'];
  soilTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type Result = {
  __typename?: 'Result';
  api_catalog_metering: ApiCatalogMeteringMembers;
  data_market_metering: DataMarketMeteringMembers;
  pientere_tuinen_measurements: PientereTuinenMeasurementsMembers;
};


export type ResultApi_Catalog_MeteringArgs = {
  orderBy?: InputMaybe<ApiCatalogMeteringOrderByInput>;
  where?: InputMaybe<ApiCatalogMeteringWhereInput>;
};


export type ResultData_Market_MeteringArgs = {
  orderBy?: InputMaybe<DataMarketMeteringOrderByInput>;
  where?: InputMaybe<DataMarketMeteringWhereInput>;
};


export type ResultPientere_Tuinen_MeasurementsArgs = {
  orderBy?: InputMaybe<PientereTuinenMeasurementsOrderByInput>;
  where?: InputMaybe<PientereTuinenMeasurementsWhereInput>;
};

export enum RoleOrganization {
  OrgDataMarketSubscriptionManager = 'ORG_DATA_MARKET_SUBSCRIPTION_MANAGER',
  OrgDataMarketUser = 'ORG_DATA_MARKET_USER',
  OrgPientereTuinenManager = 'ORG_PIENTERE_TUINEN_MANAGER',
  OrgSensorRegisterManager = 'ORG_SENSOR_REGISTER_MANAGER',
  OrgSensorRegisterUser = 'ORG_SENSOR_REGISTER_USER'
}

export enum RoleUser {
  UserApiCatalogUser = 'USER_API_CATALOG_USER',
  UserGlobalAdmin = 'USER_GLOBAL_ADMIN',
  UserPientereTuinenUser = 'USER_PIENTERE_TUINEN_USER',
  UserTreeMonitorUser = 'USER_TREE_MONITOR_USER'
}

export type RootOrderByInput = {
  api_catalog_metering?: InputMaybe<ApiCatalogMeteringOrderByInput>;
  data_market_metering?: InputMaybe<DataMarketMeteringOrderByInput>;
  pientere_tuinen_measurements?: InputMaybe<PientereTuinenMeasurementsOrderByInput>;
};

export type RootWhereInput = {
  AND?: InputMaybe<Array<RootWhereInput>>;
  OR?: InputMaybe<Array<RootWhereInput>>;
  api_catalog_metering?: InputMaybe<ApiCatalogMeteringWhereInput>;
  data_market_metering?: InputMaybe<DataMarketMeteringWhereInput>;
  pientere_tuinen_measurements?: InputMaybe<PientereTuinenMeasurementsWhereInput>;
};

export type Sensor = {
  id: Scalars['ID']['output'];
};

export type SensorError = {
  __typename?: 'SensorError';
  dateTime?: Maybe<Scalars['Instant']['output']>;
  type: SensorErrorType;
};

export enum SensorErrorType {
  /** The measurement has a unit that we did not expect */
  UnexpectedUnit = 'UNEXPECTED_UNIT',
  /** The measured value is so far out of the expected range that the sensor is probably faulty */
  ValueOutOfExpectedRange = 'VALUE_OUT_OF_EXPECTED_RANGE'
}

/**
 * Used as a temporary map between user and org so we know when a user tries to register a sensor, what org they should
 * belong to
 */
export type SensorRegistrationToken = {
  __typename?: 'SensorRegistrationToken';
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type SensorsMutation = {
  __typename?: 'SensorsMutation';
  deleteMyRegistrationToken?: Maybe<Scalars['ID']['output']>;
  registerSensoterraSensor: SensoterraSensor;
};


export type SensorsMutationRegisterSensoterraSensorArgs = {
  command: RegisterSensoterraSensorCommand;
};

export type SensoterraMeasurements = {
  __typename?: 'SensoterraMeasurements';
  humidity: Array<PossibleHumidityMeasurement>;
  temperature: Array<PossibleTemperatureMeasurement>;
};

export type SensoterraMetric = {
  __typename?: 'SensoterraMetric';
  height?: Maybe<Scalars['Int']['output']>;
  timestamp?: Maybe<Scalars['Instant']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum SensoterraMetricAggregate {
  Day = 'DAY',
  Hour = 'HOUR'
}

export type SensoterraQuery = {
  aggregate?: InputMaybe<SensoterraMetricAggregate>;
  from: Scalars['Instant']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['Instant']['input'];
};

export type SensoterraSensor = Sensor & {
  __typename?: 'SensoterraSensor';
  data: Array<SensoterraMetric>;
  depthId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  measurements: SensoterraMeasurements;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SensoterraStatus>;
};


export type SensoterraSensorDataArgs = {
  aggregate?: InputMaybe<SensoterraMetricAggregate>;
  from: Scalars['Instant']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['Instant']['input'];
};


export type SensoterraSensorMeasurementsArgs = {
  query: SensoterraQuery;
};

export type SensoterraStatus = {
  __typename?: 'SensoterraStatus';
  battery?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Instant']['output']>;
};

export type SetHttpDataSourceCommand = {
  basePath: Scalars['String']['input'];
  dataProductId: Scalars['ID']['input'];
  forwardedHeaders: Array<ForwardedHeaderCommand>;
  forwardedHostname?: InputMaybe<Scalars['String']['input']>;
  host: Scalars['String']['input'];
  port: Scalars['Int']['input'];
  scheme: HttpScheme;
};

export type SetOrganizationRolesCommand = {
  orgId: Scalars['ID']['input'];
  roles: Array<RoleOrganization>;
  userId: Scalars['ID']['input'];
};

export type SetUserRolesCommand = {
  roles: Array<RoleUser>;
  userId: Scalars['ID']['input'];
};

export type StringFilter = {
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endsWith?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notContains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notEndsWith?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notEquals?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notStartsWith?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  set?: InputMaybe<Scalars['Boolean']['input']>;
  startsWith?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TemperatureMeasurement = {
  __typename?: 'TemperatureMeasurement';
  dateTime: Scalars['Instant']['output'];
  unit?: Maybe<TemperatureUnit>;
  /** No measurement is expressed as null */
  value?: Maybe<Scalars['Float']['output']>;
};

export enum TemperatureUnit {
  Celsius = 'CELSIUS',
  Fahrenheit = 'FAHRENHEIT'
}

export type TimeDimension = {
  __typename?: 'TimeDimension';
  day: Scalars['DateTime']['output'];
  hour: Scalars['DateTime']['output'];
  minute: Scalars['DateTime']['output'];
  month: Scalars['DateTime']['output'];
  quarter: Scalars['DateTime']['output'];
  second: Scalars['DateTime']['output'];
  value: Scalars['DateTime']['output'];
  week: Scalars['DateTime']['output'];
  year: Scalars['DateTime']['output'];
};

export type Tree = {
  __typename?: 'Tree';
  benefits?: Maybe<TreeBenefits>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  sizes: TreeSizes;
  species?: Maybe<TreeSpecies>;
};

export type TreeBenefits = {
  __typename?: 'TreeBenefits';
  worthInEuro?: Maybe<Scalars['Float']['output']>;
  yearlyCapturedCo2InKg?: Maybe<Scalars['Float']['output']>;
  yearlyCapturedPollutionInG?: Maybe<Scalars['Float']['output']>;
  yearlyCapturedWaterInM3?: Maybe<Scalars['Float']['output']>;
};

export type TreeSearchQuery = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxLatitude: Scalars['Float']['input'];
  maxLongitude: Scalars['Float']['input'];
  minLatitude: Scalars['Float']['input'];
  minLongitude: Scalars['Float']['input'];
};

export type TreeSizes = {
  __typename?: 'TreeSizes';
  crownDiameterClass?: Maybe<HeightInMeterClass>;
  crownVolumeInM3?: Maybe<Scalars['Float']['output']>;
  heightClass?: Maybe<HeightInMeterClass>;
  preferredCrownCutHeightInM?: Maybe<Scalars['Float']['output']>;
  trunkDiameterInCm?: Maybe<Scalars['Int']['output']>;
};

export type TreeSpecies = {
  __typename?: 'TreeSpecies';
  code?: Maybe<Scalars['String']['output']>;
  cropType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  scientificName?: Maybe<Scalars['String']['output']>;
};

export type Trees = {
  __typename?: 'Trees';
  tree: Tree;
  treeSearch: Array<Tree>;
  treeSpecies: Array<TreeSpecies>;
};


export type TreesTreeArgs = {
  id: Scalars['ID']['input'];
};


export type TreesTreeSearchArgs = {
  query: TreeSearchQuery;
};

export type TreesMutation = {
  __typename?: 'TreesMutation';
  createTree: Tree;
  deleteTree: Scalars['ID']['output'];
  updateTree: Tree;
};


export type TreesMutationCreateTreeArgs = {
  command: CreateTreeCommand;
};


export type TreesMutationDeleteTreeArgs = {
  command?: InputMaybe<DeleteTreeCommand>;
};


export type TreesMutationUpdateTreeArgs = {
  command: UpdateTreeCommand;
};

export type UpdateDataProductPlanSubscription = {
  active: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  purpose: Scalars['String']['input'];
};

export type UpdateOrganizationCommand = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateProductCommand = {
  active: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  openDataProductSpecification: Scalars['Json']['input'];
};

export type UpdateTreeCommand = {
  crownDiameterClass: HeightInMeterClass;
  heightClass: HeightInMeterClass;
  id: Scalars['ID']['input'];
  inputSpecies: InputSpecies;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  trunkDiameterInCm?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserCommand = {
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastname: Scalars['String']['input'];
};

export type UpsertUserProductCommand = {
  dataType: DataProductDataType;
  id?: InputMaybe<Scalars['ID']['input']>;
  openDataProductSpecification: Scalars['Json']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['Instant']['output'];
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** This is an expensive call since we need to reach out to our IAM provider */
  isEmailVerified: Scalars['Boolean']['output'];
  lastname: Scalars['String']['output'];
  organizations: Array<OrganizationUser>;
  updatedAt: Scalars['Instant']['output'];
  userRoles: Array<RoleUser>;
};

export type UserSearchQuery = {
  /** String to search for in firstname, lastname and email. Minimum 3 characters */
  searchString: Scalars['String']['input'];
};

export type UserSummary = {
  __typename?: 'UserSummary';
  createdAt: Scalars['Instant']['output'];
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
  updatedAt: Scalars['Instant']['output'];
};

export type UsersMutation = {
  __typename?: 'UsersMutation';
  bulkAddOrganizationRoles: Scalars['Boolean']['output'];
  bulkAddUserRoles: Scalars['Boolean']['output'];
  bulkDeleteOrganizationRoles: Scalars['Boolean']['output'];
  bulkDeleteUserRoles: Scalars['Boolean']['output'];
  createUser: User;
  promoteDmiUser: User;
  sendEmailVerification: Scalars['Boolean']['output'];
  setOrganizationRoles: Array<RoleOrganization>;
  setUserRoles: Array<RoleUser>;
  updateUser: User;
};


export type UsersMutationBulkAddOrganizationRolesArgs = {
  command: BulkOrganizationRolesCommand;
};


export type UsersMutationBulkAddUserRolesArgs = {
  command: BulkUserRolesCommand;
};


export type UsersMutationBulkDeleteOrganizationRolesArgs = {
  command: BulkOrganizationRolesCommand;
};


export type UsersMutationBulkDeleteUserRolesArgs = {
  command: BulkUserRolesCommand;
};


export type UsersMutationCreateUserArgs = {
  command: CreateUserCommand;
};


export type UsersMutationPromoteDmiUserArgs = {
  command: PromoteDmiUserCommand;
};


export type UsersMutationSendEmailVerificationArgs = {
  id: Scalars['ID']['input'];
};


export type UsersMutationSetOrganizationRolesArgs = {
  command: SetOrganizationRolesCommand;
};


export type UsersMutationSetUserRolesArgs = {
  command: SetUserRolesCommand;
};


export type UsersMutationUpdateUserArgs = {
  command: UpdateUserCommand;
};

export type GetApisQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApisQuery = { __typename?: 'Query', apiCatalog: { __typename?: 'ApiCatalog', apis: Array<{ __typename?: 'Api', name: ApiName, plans: Array<{ __typename?: 'ApiPlan', slug: string }> }> } };

export type GetApiSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApiSubscriptionsQuery = { __typename?: 'Query', apiCatalog: { __typename?: 'ApiCatalog', userSubscriptions: Array<{ __typename?: 'ApiSubscriptionUser', id: string, createdAt: any, api: { __typename?: 'Api', name: ApiName }, plan: { __typename?: 'ApiPlan', slug: string }, keys: Array<{ __typename?: 'ApiKey', id: string, key: string, createdAt: any, updatedAt: any }> }> } };

export type GetApiCatalogMeteringQueryVariables = Exact<{
  where: ApiCatalogMeteringWhereInput;
}>;


export type GetApiCatalogMeteringQuery = { __typename?: 'Query', cube: Array<{ __typename?: 'Result', api_catalog_metering: { __typename?: 'ApiCatalogMeteringMembers', sum_calls?: number | null, day?: { __typename?: 'TimeDimension', day: any } | null } }> };

export type CreateUserSubscriptionMutationVariables = Exact<{
  command: CreateUserApiSubscriptionCommand;
}>;


export type CreateUserSubscriptionMutation = { __typename?: 'Mutation', apiCatalog: { __typename?: 'ApiCatalogMutation', createUserSubscription: { __typename?: 'ApiSubscriptionUser', id: string, createdAt: any, api: { __typename?: 'Api', name: ApiName }, plan: { __typename?: 'ApiPlan', slug: string }, keys: Array<{ __typename?: 'ApiKey', id: string, key: string, createdAt: any, updatedAt: any }> } } };

export type CreateApiSubscriptionKeyMutationVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
}>;


export type CreateApiSubscriptionKeyMutation = { __typename?: 'Mutation', apiCatalog: { __typename?: 'ApiCatalogMutation', createUserApiKey: Array<{ __typename?: 'ApiKey', id: string, key: string, createdAt: any, updatedAt: any }> } };

export type DeleteApiSubscriptionKeyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteApiSubscriptionKeyMutation = { __typename?: 'Mutation', apiCatalog: { __typename?: 'ApiCatalogMutation', deleteUserApiKey: string } };

export type DeleteApiSubscriptionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteApiSubscriptionMutation = { __typename?: 'Mutation', apiCatalog: { __typename?: 'ApiCatalogMutation', deleteUserSubscription: string } };

export type DataProductsPartsFragment = { __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any };

export type DataProductPartsWithHttpSourceFragment = { __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any, httpSource?: { __typename?: 'DataProductHttpSource', scheme: HttpScheme, host: string, port: number, basePath: string, forwardedHostname?: string | null, forwardedHeaders: Array<{ __typename?: 'ForwardedHeader', header: string, value: string }> } | null };

export type GetDataProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDataProductsQuery = { __typename?: 'Query', dataMarket: { __typename?: 'DataMarket', products: Array<{ __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any }> } };

export type GetDataProductFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDataProductFiltersQuery = { __typename?: 'Query', dataProductFilter: { __typename?: 'DataProductFilter', locations: Array<{ __typename?: 'DataProductLocation', name: string, type: DataProductLocationType }>, themes: Array<{ __typename?: 'DataProductTheme', name: string, type: DataProductThemeType }> } };

export type GetMyProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyProductsQuery = { __typename?: 'Query', dataMarket: { __typename?: 'DataMarket', userProducts: Array<{ __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any }> } };

export type GetMyProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetMyProductQuery = { __typename?: 'Query', dataMarket: { __typename?: 'DataMarket', userProduct: { __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any } } };

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = { __typename?: 'Query', dataMarket: { __typename?: 'DataMarket', subscriptions?: Array<{ __typename?: 'DataProductPlanSubscription', id: string, planId: string, active: boolean, purpose: string, createdAt: any, updatedAt: any, product: { __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any }, keys: Array<{ __typename?: 'DataProductPlanSubscriptionApiKey', id: string, createdAt: any, key: string }> }> | null } };

export type GetSubscriptionMeteringQueryVariables = Exact<{
  where: DataMarketMeteringWhereInput;
}>;


export type GetSubscriptionMeteringQuery = { __typename?: 'Query', cube: Array<{ __typename?: 'Result', data_market_metering: { __typename?: 'DataMarketMeteringMembers', sum_calls?: number | null, day?: { __typename?: 'TimeDimension', day: any } | null } }> };

export type GetAdminDataProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminDataProductsQuery = { __typename?: 'Query', dataMarket: { __typename?: 'DataMarket', adminProducts: Array<{ __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any, httpSource?: { __typename?: 'DataProductHttpSource', scheme: HttpScheme, host: string, port: number, basePath: string, forwardedHostname?: string | null, forwardedHeaders: Array<{ __typename?: 'ForwardedHeader', header: string, value: string }> } | null }> } };

export type GetAdminDataProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAdminDataProductQuery = { __typename?: 'Query', dataMarket: { __typename?: 'DataMarket', adminProduct: { __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any, httpSource?: { __typename?: 'DataProductHttpSource', scheme: HttpScheme, host: string, port: number, basePath: string, forwardedHostname?: string | null, forwardedHeaders: Array<{ __typename?: 'ForwardedHeader', header: string, value: string }> } | null } } };

export type SearchDataProductsQueryVariables = Exact<{
  query: DataProductSearchQuery;
}>;


export type SearchDataProductsQuery = { __typename?: 'Query', dataProductSearch: Array<{ __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any }> };

export type UpsertDataProductMutationVariables = Exact<{
  command: UpsertUserProductCommand;
}>;


export type UpsertDataProductMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', userUpsertProduct: { __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any } } };

export type SubmitDataProductMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SubmitDataProductMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', userSubmitProduct: string } };

export type DeleteDataProductMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteDataProductMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', userDeleteProduct: string } };

export type AdminCreateDataProductMutationVariables = Exact<{
  command: CreateAdminProductCommand;
}>;


export type AdminCreateDataProductMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', adminCreateProduct: { __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any, httpSource?: { __typename?: 'DataProductHttpSource', scheme: HttpScheme, host: string, port: number, basePath: string, forwardedHostname?: string | null, forwardedHeaders: Array<{ __typename?: 'ForwardedHeader', header: string, value: string }> } | null } } };

export type AdminUpdateDataProductMutationVariables = Exact<{
  command: CreateAdminProductCommand;
}>;


export type AdminUpdateDataProductMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', adminUpdateProduct: { __typename?: 'DataProduct', id: string, name: string, description: string, dataType: DataProductDataType, active: boolean, state: string, supplier: string, openDataProductSpecification: any, httpSource?: { __typename?: 'DataProductHttpSource', scheme: HttpScheme, host: string, port: number, basePath: string, forwardedHostname?: string | null, forwardedHeaders: Array<{ __typename?: 'ForwardedHeader', header: string, value: string }> } | null } } };

export type AdminDeleteDataProductMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AdminDeleteDataProductMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', adminDeleteProduct: string } };

export type AdminAcceptDataProductMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AdminAcceptDataProductMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', adminAcceptProduct: string } };

export type AdminRequestAmendmentDataProductMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AdminRequestAmendmentDataProductMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', adminRequestAmendmentProduct: string } };

export type SetDataProductHttpSourceMutationVariables = Exact<{
  command: SetHttpDataSourceCommand;
}>;


export type SetDataProductHttpSourceMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', adminSetHttpSource: { __typename?: 'DataProductHttpSource', scheme: HttpScheme, host: string, basePath: string, port: number, forwardedHostname?: string | null, forwardedHeaders: Array<{ __typename?: 'ForwardedHeader', header: string, value: string }> } } };

export type CreateApiKeyMutationVariables = Exact<{
  command: CreateDataProductPlanSubscriptionApiKey;
}>;


export type CreateApiKeyMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', createApiKey: { __typename?: 'DataProductPlanSubscription', id: string, createdAt: any, updatedAt: any } } };

export type DeleteApiKeyMutationVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
  keyId: Scalars['ID']['input'];
}>;


export type DeleteApiKeyMutation = { __typename?: 'Mutation', dataMarket: { __typename?: 'DataMarketMutation', deleteApiKey: { __typename?: 'DataProductPlanSubscription', id: string, createdAt: any, updatedAt: any } } };

export type GetMyUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUserRolesQuery = { __typename?: 'Query', myUserRoles: Array<RoleUser> };

export type GetMyOrganizationRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyOrganizationRolesQuery = { __typename?: 'Query', myOrganizationRoles: Array<{ __typename?: 'OrganizationUser', organizationRoles: Array<RoleOrganization>, organization: { __typename?: 'Organization', orgId: string } }> };

export type CreateUserMutationVariables = Exact<{
  command: CreateUserCommand;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', users: { __typename?: 'UsersMutation', createUser: { __typename?: 'User', id: string } } };

export const DataProductsPartsFragmentDoc = gql`
    fragment DataProductsParts on DataProduct {
  id
  name
  description
  dataType
  active
  state
  supplier
  openDataProductSpecification
}
    `;
export const DataProductPartsWithHttpSourceFragmentDoc = gql`
    fragment DataProductPartsWithHttpSource on DataProduct {
  id
  name
  description
  dataType
  active
  state
  supplier
  openDataProductSpecification
  httpSource {
    scheme
    host
    port
    basePath
    forwardedHostname
    forwardedHeaders {
      header
      value
    }
  }
}
    `;
export const GetApisDocument = gql`
    query GetApis {
  apiCatalog {
    apis {
      name
      plans {
        slug
      }
    }
  }
}
    `;

/**
 * __useGetApisQuery__
 *
 * To run a query within a React component, call `useGetApisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApisQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApisQuery(baseOptions?: Apollo.QueryHookOptions<GetApisQuery, GetApisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApisQuery, GetApisQueryVariables>(GetApisDocument, options);
      }
export function useGetApisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApisQuery, GetApisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApisQuery, GetApisQueryVariables>(GetApisDocument, options);
        }
export type GetApisQueryHookResult = ReturnType<typeof useGetApisQuery>;
export type GetApisLazyQueryHookResult = ReturnType<typeof useGetApisLazyQuery>;
export type GetApisQueryResult = Apollo.QueryResult<GetApisQuery, GetApisQueryVariables>;
export const GetApiSubscriptionsDocument = gql`
    query GetApiSubscriptions {
  apiCatalog {
    userSubscriptions {
      id
      createdAt
      api {
        name
      }
      plan {
        slug
      }
      keys {
        id
        key
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetApiSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetApiSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApiSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetApiSubscriptionsQuery, GetApiSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiSubscriptionsQuery, GetApiSubscriptionsQueryVariables>(GetApiSubscriptionsDocument, options);
      }
export function useGetApiSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiSubscriptionsQuery, GetApiSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiSubscriptionsQuery, GetApiSubscriptionsQueryVariables>(GetApiSubscriptionsDocument, options);
        }
export type GetApiSubscriptionsQueryHookResult = ReturnType<typeof useGetApiSubscriptionsQuery>;
export type GetApiSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetApiSubscriptionsLazyQuery>;
export type GetApiSubscriptionsQueryResult = Apollo.QueryResult<GetApiSubscriptionsQuery, GetApiSubscriptionsQueryVariables>;
export const GetApiCatalogMeteringDocument = gql`
    query GetApiCatalogMetering($where: ApiCatalogMeteringWhereInput!) {
  cube(where: {api_catalog_metering: $where}) {
    api_catalog_metering(orderBy: {day: asc}) {
      sum_calls
      day {
        day
      }
    }
  }
}
    `;

/**
 * __useGetApiCatalogMeteringQuery__
 *
 * To run a query within a React component, call `useGetApiCatalogMeteringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiCatalogMeteringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiCatalogMeteringQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetApiCatalogMeteringQuery(baseOptions: Apollo.QueryHookOptions<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>(GetApiCatalogMeteringDocument, options);
      }
export function useGetApiCatalogMeteringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>(GetApiCatalogMeteringDocument, options);
        }
export type GetApiCatalogMeteringQueryHookResult = ReturnType<typeof useGetApiCatalogMeteringQuery>;
export type GetApiCatalogMeteringLazyQueryHookResult = ReturnType<typeof useGetApiCatalogMeteringLazyQuery>;
export type GetApiCatalogMeteringQueryResult = Apollo.QueryResult<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>;
export const CreateUserSubscriptionDocument = gql`
    mutation CreateUserSubscription($command: CreateUserApiSubscriptionCommand!) {
  apiCatalog {
    createUserSubscription(command: $command) {
      id
      createdAt
      api {
        name
      }
      plan {
        slug
      }
      keys {
        id
        key
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export type CreateUserSubscriptionMutationFn = Apollo.MutationFunction<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>;

/**
 * __useCreateUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubscriptionMutation, { data, loading, error }] = useCreateUserSubscriptionMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateUserSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>(CreateUserSubscriptionDocument, options);
      }
export type CreateUserSubscriptionMutationHookResult = ReturnType<typeof useCreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationResult = Apollo.MutationResult<CreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>;
export const CreateApiSubscriptionKeyDocument = gql`
    mutation CreateApiSubscriptionKey($subscriptionId: ID!) {
  apiCatalog {
    createUserApiKey(subscriptionId: $subscriptionId) {
      id
      key
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateApiSubscriptionKeyMutationFn = Apollo.MutationFunction<CreateApiSubscriptionKeyMutation, CreateApiSubscriptionKeyMutationVariables>;

/**
 * __useCreateApiSubscriptionKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiSubscriptionKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiSubscriptionKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiSubscriptionKeyMutation, { data, loading, error }] = useCreateApiSubscriptionKeyMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCreateApiSubscriptionKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiSubscriptionKeyMutation, CreateApiSubscriptionKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiSubscriptionKeyMutation, CreateApiSubscriptionKeyMutationVariables>(CreateApiSubscriptionKeyDocument, options);
      }
export type CreateApiSubscriptionKeyMutationHookResult = ReturnType<typeof useCreateApiSubscriptionKeyMutation>;
export type CreateApiSubscriptionKeyMutationResult = Apollo.MutationResult<CreateApiSubscriptionKeyMutation>;
export type CreateApiSubscriptionKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiSubscriptionKeyMutation, CreateApiSubscriptionKeyMutationVariables>;
export const DeleteApiSubscriptionKeyDocument = gql`
    mutation DeleteApiSubscriptionKey($id: ID!) {
  apiCatalog {
    deleteUserApiKey(id: $id)
  }
}
    `;
export type DeleteApiSubscriptionKeyMutationFn = Apollo.MutationFunction<DeleteApiSubscriptionKeyMutation, DeleteApiSubscriptionKeyMutationVariables>;

/**
 * __useDeleteApiSubscriptionKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiSubscriptionKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiSubscriptionKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiSubscriptionKeyMutation, { data, loading, error }] = useDeleteApiSubscriptionKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiSubscriptionKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiSubscriptionKeyMutation, DeleteApiSubscriptionKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiSubscriptionKeyMutation, DeleteApiSubscriptionKeyMutationVariables>(DeleteApiSubscriptionKeyDocument, options);
      }
export type DeleteApiSubscriptionKeyMutationHookResult = ReturnType<typeof useDeleteApiSubscriptionKeyMutation>;
export type DeleteApiSubscriptionKeyMutationResult = Apollo.MutationResult<DeleteApiSubscriptionKeyMutation>;
export type DeleteApiSubscriptionKeyMutationOptions = Apollo.BaseMutationOptions<DeleteApiSubscriptionKeyMutation, DeleteApiSubscriptionKeyMutationVariables>;
export const DeleteApiSubscriptionDocument = gql`
    mutation DeleteApiSubscription($id: ID!) {
  apiCatalog {
    deleteUserSubscription(id: $id)
  }
}
    `;
export type DeleteApiSubscriptionMutationFn = Apollo.MutationFunction<DeleteApiSubscriptionMutation, DeleteApiSubscriptionMutationVariables>;

/**
 * __useDeleteApiSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteApiSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiSubscriptionMutation, { data, loading, error }] = useDeleteApiSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiSubscriptionMutation, DeleteApiSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiSubscriptionMutation, DeleteApiSubscriptionMutationVariables>(DeleteApiSubscriptionDocument, options);
      }
export type DeleteApiSubscriptionMutationHookResult = ReturnType<typeof useDeleteApiSubscriptionMutation>;
export type DeleteApiSubscriptionMutationResult = Apollo.MutationResult<DeleteApiSubscriptionMutation>;
export type DeleteApiSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteApiSubscriptionMutation, DeleteApiSubscriptionMutationVariables>;
export const GetDataProductsDocument = gql`
    query GetDataProducts {
  dataMarket {
    products {
      ...DataProductsParts
    }
  }
}
    ${DataProductsPartsFragmentDoc}`;

/**
 * __useGetDataProductsQuery__
 *
 * To run a query within a React component, call `useGetDataProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetDataProductsQuery, GetDataProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataProductsQuery, GetDataProductsQueryVariables>(GetDataProductsDocument, options);
      }
export function useGetDataProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataProductsQuery, GetDataProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataProductsQuery, GetDataProductsQueryVariables>(GetDataProductsDocument, options);
        }
export type GetDataProductsQueryHookResult = ReturnType<typeof useGetDataProductsQuery>;
export type GetDataProductsLazyQueryHookResult = ReturnType<typeof useGetDataProductsLazyQuery>;
export type GetDataProductsQueryResult = Apollo.QueryResult<GetDataProductsQuery, GetDataProductsQueryVariables>;
export const GetDataProductFiltersDocument = gql`
    query GetDataProductFilters {
  dataProductFilter {
    locations {
      name
      type
    }
    themes {
      name
      type
    }
  }
}
    `;

/**
 * __useGetDataProductFiltersQuery__
 *
 * To run a query within a React component, call `useGetDataProductFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataProductFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataProductFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataProductFiltersQuery(baseOptions?: Apollo.QueryHookOptions<GetDataProductFiltersQuery, GetDataProductFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataProductFiltersQuery, GetDataProductFiltersQueryVariables>(GetDataProductFiltersDocument, options);
      }
export function useGetDataProductFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataProductFiltersQuery, GetDataProductFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataProductFiltersQuery, GetDataProductFiltersQueryVariables>(GetDataProductFiltersDocument, options);
        }
export type GetDataProductFiltersQueryHookResult = ReturnType<typeof useGetDataProductFiltersQuery>;
export type GetDataProductFiltersLazyQueryHookResult = ReturnType<typeof useGetDataProductFiltersLazyQuery>;
export type GetDataProductFiltersQueryResult = Apollo.QueryResult<GetDataProductFiltersQuery, GetDataProductFiltersQueryVariables>;
export const GetMyProductsDocument = gql`
    query GetMyProducts {
  dataMarket {
    userProducts {
      ...DataProductsParts
    }
  }
}
    ${DataProductsPartsFragmentDoc}`;

/**
 * __useGetMyProductsQuery__
 *
 * To run a query within a React component, call `useGetMyProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyProductsQuery, GetMyProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProductsQuery, GetMyProductsQueryVariables>(GetMyProductsDocument, options);
      }
export function useGetMyProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProductsQuery, GetMyProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProductsQuery, GetMyProductsQueryVariables>(GetMyProductsDocument, options);
        }
export type GetMyProductsQueryHookResult = ReturnType<typeof useGetMyProductsQuery>;
export type GetMyProductsLazyQueryHookResult = ReturnType<typeof useGetMyProductsLazyQuery>;
export type GetMyProductsQueryResult = Apollo.QueryResult<GetMyProductsQuery, GetMyProductsQueryVariables>;
export const GetMyProductDocument = gql`
    query GetMyProduct($id: ID!) {
  dataMarket {
    userProduct(id: $id) {
      ...DataProductsParts
    }
  }
}
    ${DataProductsPartsFragmentDoc}`;

/**
 * __useGetMyProductQuery__
 *
 * To run a query within a React component, call `useGetMyProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMyProductQuery(baseOptions: Apollo.QueryHookOptions<GetMyProductQuery, GetMyProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProductQuery, GetMyProductQueryVariables>(GetMyProductDocument, options);
      }
export function useGetMyProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProductQuery, GetMyProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProductQuery, GetMyProductQueryVariables>(GetMyProductDocument, options);
        }
export type GetMyProductQueryHookResult = ReturnType<typeof useGetMyProductQuery>;
export type GetMyProductLazyQueryHookResult = ReturnType<typeof useGetMyProductLazyQuery>;
export type GetMyProductQueryResult = Apollo.QueryResult<GetMyProductQuery, GetMyProductQueryVariables>;
export const GetSubscriptionsDocument = gql`
    query GetSubscriptions {
  dataMarket {
    subscriptions {
      id
      product {
        ...DataProductsParts
      }
      planId
      active
      purpose
      keys {
        id
        createdAt
        key
      }
      createdAt
      updatedAt
    }
  }
}
    ${DataProductsPartsFragmentDoc}`;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>;
export const GetSubscriptionMeteringDocument = gql`
    query GetSubscriptionMetering($where: DataMarketMeteringWhereInput!) {
  cube(limit: 365, where: {data_market_metering: $where}) {
    data_market_metering(orderBy: {day: asc}) {
      sum_calls
      day {
        day
      }
    }
  }
}
    `;

/**
 * __useGetSubscriptionMeteringQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionMeteringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionMeteringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionMeteringQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSubscriptionMeteringQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriptionMeteringQuery, GetSubscriptionMeteringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionMeteringQuery, GetSubscriptionMeteringQueryVariables>(GetSubscriptionMeteringDocument, options);
      }
export function useGetSubscriptionMeteringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionMeteringQuery, GetSubscriptionMeteringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionMeteringQuery, GetSubscriptionMeteringQueryVariables>(GetSubscriptionMeteringDocument, options);
        }
export type GetSubscriptionMeteringQueryHookResult = ReturnType<typeof useGetSubscriptionMeteringQuery>;
export type GetSubscriptionMeteringLazyQueryHookResult = ReturnType<typeof useGetSubscriptionMeteringLazyQuery>;
export type GetSubscriptionMeteringQueryResult = Apollo.QueryResult<GetSubscriptionMeteringQuery, GetSubscriptionMeteringQueryVariables>;
export const GetAdminDataProductsDocument = gql`
    query GetAdminDataProducts {
  dataMarket {
    adminProducts {
      ...DataProductPartsWithHttpSource
    }
  }
}
    ${DataProductPartsWithHttpSourceFragmentDoc}`;

/**
 * __useGetAdminDataProductsQuery__
 *
 * To run a query within a React component, call `useGetAdminDataProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDataProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDataProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminDataProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminDataProductsQuery, GetAdminDataProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminDataProductsQuery, GetAdminDataProductsQueryVariables>(GetAdminDataProductsDocument, options);
      }
export function useGetAdminDataProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminDataProductsQuery, GetAdminDataProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminDataProductsQuery, GetAdminDataProductsQueryVariables>(GetAdminDataProductsDocument, options);
        }
export type GetAdminDataProductsQueryHookResult = ReturnType<typeof useGetAdminDataProductsQuery>;
export type GetAdminDataProductsLazyQueryHookResult = ReturnType<typeof useGetAdminDataProductsLazyQuery>;
export type GetAdminDataProductsQueryResult = Apollo.QueryResult<GetAdminDataProductsQuery, GetAdminDataProductsQueryVariables>;
export const GetAdminDataProductDocument = gql`
    query GetAdminDataProduct($id: ID!) {
  dataMarket {
    adminProduct(id: $id) {
      ...DataProductPartsWithHttpSource
    }
  }
}
    ${DataProductPartsWithHttpSourceFragmentDoc}`;

/**
 * __useGetAdminDataProductQuery__
 *
 * To run a query within a React component, call `useGetAdminDataProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDataProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDataProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminDataProductQuery(baseOptions: Apollo.QueryHookOptions<GetAdminDataProductQuery, GetAdminDataProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminDataProductQuery, GetAdminDataProductQueryVariables>(GetAdminDataProductDocument, options);
      }
export function useGetAdminDataProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminDataProductQuery, GetAdminDataProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminDataProductQuery, GetAdminDataProductQueryVariables>(GetAdminDataProductDocument, options);
        }
export type GetAdminDataProductQueryHookResult = ReturnType<typeof useGetAdminDataProductQuery>;
export type GetAdminDataProductLazyQueryHookResult = ReturnType<typeof useGetAdminDataProductLazyQuery>;
export type GetAdminDataProductQueryResult = Apollo.QueryResult<GetAdminDataProductQuery, GetAdminDataProductQueryVariables>;
export const SearchDataProductsDocument = gql`
    query SearchDataProducts($query: DataProductSearchQuery!) {
  dataProductSearch(query: $query) {
    ...DataProductsParts
  }
}
    ${DataProductsPartsFragmentDoc}`;

/**
 * __useSearchDataProductsQuery__
 *
 * To run a query within a React component, call `useSearchDataProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDataProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDataProductsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchDataProductsQuery(baseOptions: Apollo.QueryHookOptions<SearchDataProductsQuery, SearchDataProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDataProductsQuery, SearchDataProductsQueryVariables>(SearchDataProductsDocument, options);
      }
export function useSearchDataProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDataProductsQuery, SearchDataProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDataProductsQuery, SearchDataProductsQueryVariables>(SearchDataProductsDocument, options);
        }
export type SearchDataProductsQueryHookResult = ReturnType<typeof useSearchDataProductsQuery>;
export type SearchDataProductsLazyQueryHookResult = ReturnType<typeof useSearchDataProductsLazyQuery>;
export type SearchDataProductsQueryResult = Apollo.QueryResult<SearchDataProductsQuery, SearchDataProductsQueryVariables>;
export const UpsertDataProductDocument = gql`
    mutation UpsertDataProduct($command: UpsertUserProductCommand!) {
  dataMarket {
    userUpsertProduct(command: $command) {
      ...DataProductsParts
    }
  }
}
    ${DataProductsPartsFragmentDoc}`;
export type UpsertDataProductMutationFn = Apollo.MutationFunction<UpsertDataProductMutation, UpsertDataProductMutationVariables>;

/**
 * __useUpsertDataProductMutation__
 *
 * To run a mutation, you first call `useUpsertDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDataProductMutation, { data, loading, error }] = useUpsertDataProductMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useUpsertDataProductMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDataProductMutation, UpsertDataProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDataProductMutation, UpsertDataProductMutationVariables>(UpsertDataProductDocument, options);
      }
export type UpsertDataProductMutationHookResult = ReturnType<typeof useUpsertDataProductMutation>;
export type UpsertDataProductMutationResult = Apollo.MutationResult<UpsertDataProductMutation>;
export type UpsertDataProductMutationOptions = Apollo.BaseMutationOptions<UpsertDataProductMutation, UpsertDataProductMutationVariables>;
export const SubmitDataProductDocument = gql`
    mutation SubmitDataProduct($id: ID!) {
  dataMarket {
    userSubmitProduct(id: $id)
  }
}
    `;
export type SubmitDataProductMutationFn = Apollo.MutationFunction<SubmitDataProductMutation, SubmitDataProductMutationVariables>;

/**
 * __useSubmitDataProductMutation__
 *
 * To run a mutation, you first call `useSubmitDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDataProductMutation, { data, loading, error }] = useSubmitDataProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitDataProductMutation(baseOptions?: Apollo.MutationHookOptions<SubmitDataProductMutation, SubmitDataProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitDataProductMutation, SubmitDataProductMutationVariables>(SubmitDataProductDocument, options);
      }
export type SubmitDataProductMutationHookResult = ReturnType<typeof useSubmitDataProductMutation>;
export type SubmitDataProductMutationResult = Apollo.MutationResult<SubmitDataProductMutation>;
export type SubmitDataProductMutationOptions = Apollo.BaseMutationOptions<SubmitDataProductMutation, SubmitDataProductMutationVariables>;
export const DeleteDataProductDocument = gql`
    mutation DeleteDataProduct($id: ID!) {
  dataMarket {
    userDeleteProduct(id: $id)
  }
}
    `;
export type DeleteDataProductMutationFn = Apollo.MutationFunction<DeleteDataProductMutation, DeleteDataProductMutationVariables>;

/**
 * __useDeleteDataProductMutation__
 *
 * To run a mutation, you first call `useDeleteDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataProductMutation, { data, loading, error }] = useDeleteDataProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDataProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataProductMutation, DeleteDataProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataProductMutation, DeleteDataProductMutationVariables>(DeleteDataProductDocument, options);
      }
export type DeleteDataProductMutationHookResult = ReturnType<typeof useDeleteDataProductMutation>;
export type DeleteDataProductMutationResult = Apollo.MutationResult<DeleteDataProductMutation>;
export type DeleteDataProductMutationOptions = Apollo.BaseMutationOptions<DeleteDataProductMutation, DeleteDataProductMutationVariables>;
export const AdminCreateDataProductDocument = gql`
    mutation AdminCreateDataProduct($command: CreateAdminProductCommand!) {
  dataMarket {
    adminCreateProduct(command: $command) {
      ...DataProductPartsWithHttpSource
    }
  }
}
    ${DataProductPartsWithHttpSourceFragmentDoc}`;
export type AdminCreateDataProductMutationFn = Apollo.MutationFunction<AdminCreateDataProductMutation, AdminCreateDataProductMutationVariables>;

/**
 * __useAdminCreateDataProductMutation__
 *
 * To run a mutation, you first call `useAdminCreateDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateDataProductMutation, { data, loading, error }] = useAdminCreateDataProductMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useAdminCreateDataProductMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateDataProductMutation, AdminCreateDataProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateDataProductMutation, AdminCreateDataProductMutationVariables>(AdminCreateDataProductDocument, options);
      }
export type AdminCreateDataProductMutationHookResult = ReturnType<typeof useAdminCreateDataProductMutation>;
export type AdminCreateDataProductMutationResult = Apollo.MutationResult<AdminCreateDataProductMutation>;
export type AdminCreateDataProductMutationOptions = Apollo.BaseMutationOptions<AdminCreateDataProductMutation, AdminCreateDataProductMutationVariables>;
export const AdminUpdateDataProductDocument = gql`
    mutation AdminUpdateDataProduct($command: CreateAdminProductCommand!) {
  dataMarket {
    adminUpdateProduct(command: $command) {
      ...DataProductPartsWithHttpSource
    }
  }
}
    ${DataProductPartsWithHttpSourceFragmentDoc}`;
export type AdminUpdateDataProductMutationFn = Apollo.MutationFunction<AdminUpdateDataProductMutation, AdminUpdateDataProductMutationVariables>;

/**
 * __useAdminUpdateDataProductMutation__
 *
 * To run a mutation, you first call `useAdminUpdateDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateDataProductMutation, { data, loading, error }] = useAdminUpdateDataProductMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useAdminUpdateDataProductMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateDataProductMutation, AdminUpdateDataProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateDataProductMutation, AdminUpdateDataProductMutationVariables>(AdminUpdateDataProductDocument, options);
      }
export type AdminUpdateDataProductMutationHookResult = ReturnType<typeof useAdminUpdateDataProductMutation>;
export type AdminUpdateDataProductMutationResult = Apollo.MutationResult<AdminUpdateDataProductMutation>;
export type AdminUpdateDataProductMutationOptions = Apollo.BaseMutationOptions<AdminUpdateDataProductMutation, AdminUpdateDataProductMutationVariables>;
export const AdminDeleteDataProductDocument = gql`
    mutation AdminDeleteDataProduct($id: ID!) {
  dataMarket {
    adminDeleteProduct(id: $id)
  }
}
    `;
export type AdminDeleteDataProductMutationFn = Apollo.MutationFunction<AdminDeleteDataProductMutation, AdminDeleteDataProductMutationVariables>;

/**
 * __useAdminDeleteDataProductMutation__
 *
 * To run a mutation, you first call `useAdminDeleteDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteDataProductMutation, { data, loading, error }] = useAdminDeleteDataProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteDataProductMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteDataProductMutation, AdminDeleteDataProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteDataProductMutation, AdminDeleteDataProductMutationVariables>(AdminDeleteDataProductDocument, options);
      }
export type AdminDeleteDataProductMutationHookResult = ReturnType<typeof useAdminDeleteDataProductMutation>;
export type AdminDeleteDataProductMutationResult = Apollo.MutationResult<AdminDeleteDataProductMutation>;
export type AdminDeleteDataProductMutationOptions = Apollo.BaseMutationOptions<AdminDeleteDataProductMutation, AdminDeleteDataProductMutationVariables>;
export const AdminAcceptDataProductDocument = gql`
    mutation AdminAcceptDataProduct($id: ID!) {
  dataMarket {
    adminAcceptProduct(id: $id)
  }
}
    `;
export type AdminAcceptDataProductMutationFn = Apollo.MutationFunction<AdminAcceptDataProductMutation, AdminAcceptDataProductMutationVariables>;

/**
 * __useAdminAcceptDataProductMutation__
 *
 * To run a mutation, you first call `useAdminAcceptDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAcceptDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAcceptDataProductMutation, { data, loading, error }] = useAdminAcceptDataProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminAcceptDataProductMutation(baseOptions?: Apollo.MutationHookOptions<AdminAcceptDataProductMutation, AdminAcceptDataProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminAcceptDataProductMutation, AdminAcceptDataProductMutationVariables>(AdminAcceptDataProductDocument, options);
      }
export type AdminAcceptDataProductMutationHookResult = ReturnType<typeof useAdminAcceptDataProductMutation>;
export type AdminAcceptDataProductMutationResult = Apollo.MutationResult<AdminAcceptDataProductMutation>;
export type AdminAcceptDataProductMutationOptions = Apollo.BaseMutationOptions<AdminAcceptDataProductMutation, AdminAcceptDataProductMutationVariables>;
export const AdminRequestAmendmentDataProductDocument = gql`
    mutation AdminRequestAmendmentDataProduct($id: ID!) {
  dataMarket {
    adminRequestAmendmentProduct(id: $id)
  }
}
    `;
export type AdminRequestAmendmentDataProductMutationFn = Apollo.MutationFunction<AdminRequestAmendmentDataProductMutation, AdminRequestAmendmentDataProductMutationVariables>;

/**
 * __useAdminRequestAmendmentDataProductMutation__
 *
 * To run a mutation, you first call `useAdminRequestAmendmentDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRequestAmendmentDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRequestAmendmentDataProductMutation, { data, loading, error }] = useAdminRequestAmendmentDataProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRequestAmendmentDataProductMutation(baseOptions?: Apollo.MutationHookOptions<AdminRequestAmendmentDataProductMutation, AdminRequestAmendmentDataProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminRequestAmendmentDataProductMutation, AdminRequestAmendmentDataProductMutationVariables>(AdminRequestAmendmentDataProductDocument, options);
      }
export type AdminRequestAmendmentDataProductMutationHookResult = ReturnType<typeof useAdminRequestAmendmentDataProductMutation>;
export type AdminRequestAmendmentDataProductMutationResult = Apollo.MutationResult<AdminRequestAmendmentDataProductMutation>;
export type AdminRequestAmendmentDataProductMutationOptions = Apollo.BaseMutationOptions<AdminRequestAmendmentDataProductMutation, AdminRequestAmendmentDataProductMutationVariables>;
export const SetDataProductHttpSourceDocument = gql`
    mutation SetDataProductHttpSource($command: SetHttpDataSourceCommand!) {
  dataMarket {
    adminSetHttpSource(command: $command) {
      scheme
      host
      basePath
      port
      forwardedHostname
      forwardedHeaders {
        header
        value
      }
    }
  }
}
    `;
export type SetDataProductHttpSourceMutationFn = Apollo.MutationFunction<SetDataProductHttpSourceMutation, SetDataProductHttpSourceMutationVariables>;

/**
 * __useSetDataProductHttpSourceMutation__
 *
 * To run a mutation, you first call `useSetDataProductHttpSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDataProductHttpSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDataProductHttpSourceMutation, { data, loading, error }] = useSetDataProductHttpSourceMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useSetDataProductHttpSourceMutation(baseOptions?: Apollo.MutationHookOptions<SetDataProductHttpSourceMutation, SetDataProductHttpSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDataProductHttpSourceMutation, SetDataProductHttpSourceMutationVariables>(SetDataProductHttpSourceDocument, options);
      }
export type SetDataProductHttpSourceMutationHookResult = ReturnType<typeof useSetDataProductHttpSourceMutation>;
export type SetDataProductHttpSourceMutationResult = Apollo.MutationResult<SetDataProductHttpSourceMutation>;
export type SetDataProductHttpSourceMutationOptions = Apollo.BaseMutationOptions<SetDataProductHttpSourceMutation, SetDataProductHttpSourceMutationVariables>;
export const CreateApiKeyDocument = gql`
    mutation CreateApiKey($command: CreateDataProductPlanSubscriptionApiKey!) {
  dataMarket {
    createApiKey(command: $command) {
      id
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const DeleteApiKeyDocument = gql`
    mutation DeleteApiKey($subscriptionId: ID!, $keyId: ID!) {
  dataMarket {
    deleteApiKey(subscriptionId: $subscriptionId, keyId: $keyId) {
      id
      createdAt
      updatedAt
    }
  }
}
    `;
export type DeleteApiKeyMutationFn = Apollo.MutationFunction<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(DeleteApiKeyDocument, options);
      }
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = Apollo.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;
export const GetMyUserRolesDocument = gql`
    query GetMyUserRoles {
  myUserRoles
}
    `;

/**
 * __useGetMyUserRolesQuery__
 *
 * To run a query within a React component, call `useGetMyUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>(GetMyUserRolesDocument, options);
      }
export function useGetMyUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>(GetMyUserRolesDocument, options);
        }
export type GetMyUserRolesQueryHookResult = ReturnType<typeof useGetMyUserRolesQuery>;
export type GetMyUserRolesLazyQueryHookResult = ReturnType<typeof useGetMyUserRolesLazyQuery>;
export type GetMyUserRolesQueryResult = Apollo.QueryResult<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>;
export const GetMyOrganizationRolesDocument = gql`
    query GetMyOrganizationRoles {
  myOrganizationRoles {
    organization {
      orgId
    }
    organizationRoles
  }
}
    `;

/**
 * __useGetMyOrganizationRolesQuery__
 *
 * To run a query within a React component, call `useGetMyOrganizationRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrganizationRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrganizationRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOrganizationRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>(GetMyOrganizationRolesDocument, options);
      }
export function useGetMyOrganizationRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>(GetMyOrganizationRolesDocument, options);
        }
export type GetMyOrganizationRolesQueryHookResult = ReturnType<typeof useGetMyOrganizationRolesQuery>;
export type GetMyOrganizationRolesLazyQueryHookResult = ReturnType<typeof useGetMyOrganizationRolesLazyQuery>;
export type GetMyOrganizationRolesQueryResult = Apollo.QueryResult<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($command: CreateUserCommand!) {
  users {
    createUser(command: $command) {
      id
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;