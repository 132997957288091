import React from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  faBagShopping, faDatabase, faDesktopAlt, faMessageDots,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { OfferWizardIsWebserviceRoute } from '../../Routes';
import InfoButton from '../../library/InfoButton';

function ProductFormSelection() {
  const params = useParams();
  const formUrls: FormUrl[] = [
    {
      name: 'Data Product',
      url: OfferWizardIsWebserviceRoute(params.orgId, crypto.randomUUID()),
      icon: faDatabase,
      disabled: false,
      description: 'Ik bied een abonnement aan op data door middel van een API key.',
    },
    {
      name: 'Applicatie',
      url: OfferWizardIsWebserviceRoute(params.orgId, crypto.randomUUID()),
      icon: faDesktopAlt,
      disabled: true,
      description: 'Ik bied een abonnement aan op een applicatie',
    },
    {
      name: 'Consultancy',
      url: OfferWizardIsWebserviceRoute(params.orgId, crypto.randomUUID()),
      icon: faMessageDots,
      disabled: true,
      description: 'Ik bied mijn expertise aan voor een afgesproken periode.',
    },
    {
      name: 'Hardware',
      url: OfferWizardIsWebserviceRoute(params.orgId, crypto.randomUUID()),
      icon: faBagShopping,
      disabled: true,
      description: 'Ik bied een fysiek product aan voor een eenmalig bedrag of via een abbonnement.',
    },
  ];

  return (

    <div className="mx-auto max-w-screen-xl pt-10 ">
      <div className="bg-white p-10 my-10 flex flex-col gap-10">
        <div>
          <Link className="flex gap-x-2 items-center text-sm" to={OfferWizardIsWebserviceRoute(params.orgId, crypto.randomUUID())}>
            <FontAwesomeIcon
              icon={icon({ name: 'angle-left', family: 'classic', style: 'solid' })}
              size="lg"
              className="text-primary-500"
            />
            <div className="text-primary-500 text-sm">Back to my offerings</div>
          </Link>
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-4xl font-semibold">What ga je aanbieden?</div>
          <div className="text-gray-600 text-lg">Je kan verschillende dingen aanbieden; dataproducten, applicaties, consultancy of fysieke producten.</div>
        </div>
        <ul className="grid grid-cols-2 gap-10">
          {
            formUrls.map((formUrl) => (
              <div key={formUrl.name} className="grow bg-white justify-center items-center aspect-[3/2] flex rounded-xl border-2 shadow-lg text-lg">
                <div className="flex flex-col gap-5">
                  <FontAwesomeIcon icon={formUrl.icon} className="m-auto block" size="4x" color="lightgray" />
                  <div className={`text-2xl font-semibold m-auto ${formUrl.disabled && 'text-gray-400'}`}>{formUrl.name}</div>
                  <div className="text-gray-500 max-w-[400px] text-center my-3 ">{formUrl.description}</div>
                  <div className="flex justify-center">
                    <Link to={formUrl.url}>
                      <InfoButton disabled={formUrl.disabled}>
                        Start aanbod
                        <FontAwesomeIcon
                          icon={icon({ name: 'arrow-right', family: 'classic', style: 'regular' })}
                          className="ml-2"
                        />
                      </InfoButton>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          }
        </ul>
      </div>
    </div>
  );
}

export default ProductFormSelection;

type FormUrl = {
  name: string;
  url: string;
  icon: any;
  disabled: boolean;
  description?: string;
};
