import React from 'react';
import {
  Button as FlowBiteButton,
} from 'flowbite-react';
import { ButtonSizes } from 'flowbite-react/lib/esm/components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

function GrayButton({
  size, fullSized, children, disabled, onClick,
}: BadgeProps) {
  return (
    <FlowBiteButton size={size ?? 'lg'} color="gray" fullSized={fullSized ?? false} disabled={disabled} onClick={onClick}>
      <div className="w-full justify-center items-center gap-2 inline-flex">
        {children}
        <FontAwesomeIcon icon={icon({ name: 'arrow-right' })} />
      </div>
    </FlowBiteButton>
  );
}

interface BadgeProps {
  size?: keyof ButtonSizes
  fullSized?: boolean
  children: React.ReactNode
  disabled?: boolean
  onClick?: () => void
}

export default GrayButton;
