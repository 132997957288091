import React from 'react';
import {
  Button as FlowBiteButton,
} from 'flowbite-react';
import { ButtonSizes } from 'flowbite-react/lib/esm/components/Button/Button';

function InfoButton({
  size, fullSized, children, disabled,
}: BadgeProps) {
  return (
    <FlowBiteButton size={size ?? 'lg'} color="info" fullSized={fullSized ?? false} disabled={disabled}>
      <div className="w-full justify-center items-center gap-2 inline-flex">
        {children}
      </div>
    </FlowBiteButton>
  );
}

interface BadgeProps {
  size?: keyof ButtonSizes
  fullSized?: boolean
  children: React.ReactNode
  disabled?: boolean
}

export default InfoButton;
