import React from 'react';
import GrayButton from './GrayButton';
import { capitalize } from '../../Helpers';

interface IImageCardProps {
  subject: string;
  imgSrc: string;
  onButtonClick?: () => void;
}

function ImageCard({ imgSrc, subject, onButtonClick }: IImageCardProps) {
  return (
    <div className="relative h-[500px] w-[330px] rounded-lg overflow-hidden shadow-lg">
      <img src={imgSrc} alt={`${subject}`} className="h-full w-full object-cover" />
      <div className="min-w-[120px] mt-auto ml-auto m-5 absolute bottom-2 right-2">
        <GrayButton size="md" fullSized onClick={onButtonClick}>
          {capitalize(subject)}
        </GrayButton>
      </div>
    </div>
  );
}

export default ImageCard;
