import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Progress } from 'flowbite-react';
import {
  DataProduct, useDeleteDataProductMutation,
  useGetMyProductsQuery,
} from '../../generated/gql/types';
import {
  CatalogProductRoute, OfferWizardAutoFillOdpsRoute, OfferWizardIsWebserviceRoute, SelectOfferWizardRoute,
} from '../Routes';
import { OfferingsLocalStorageKey, WeCityOrganizationIdHeader } from '../../Global';
import validateOdps, { Offer } from '../../Helpers';
import ProductOfferCard from '../library/ProductOfferCard';
import InfoButton from '../library/InfoButton';
import CategoriesToIcons from '../library/CategoriesToIcons';
import Badge from '../library/Badge';
import Card from '../library/Card';

function MyOfferingsOverview() {
  const params = useParams();
  const { data, loading, refetch } = useGetMyProductsQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });
  const [deleteDataProduct] = useDeleteDataProductMutation({});
  const [myOffers, setMyOffers] = useState<Offer[] | undefined>(undefined);

  function deleteOfferFromLocalStorage(id: string) {
    const offers = (JSON.parse(localStorage.getItem(OfferingsLocalStorageKey) ?? '[]') ?? []) as Offer[];
    const updatedOffers = offers.filter((o) => o.id !== id);
    localStorage.setItem(OfferingsLocalStorageKey, JSON.stringify(updatedOffers));
    setMyOffers(updatedOffers);
  }

  function deleteInProgressOffer(id: string) {
    deleteDataProduct({
      variables: {
        id,
      },
      context: {
        headers: {
          [WeCityOrganizationIdHeader]: params.orgId,
        },
      },
    }).then((_) => {
      deleteOfferFromLocalStorage(id);
      refetch();
    });
  }

  useEffect(() => {
    const items = (JSON.parse(localStorage.getItem(OfferingsLocalStorageKey) ?? '[]') ?? []) as Offer[];

    if (data?.dataMarket.userProducts && !loading) {
      // Filter submitted items
      const inProgressOffers = items.filter((offer) => data?.dataMarket.userProducts.find((o) => o.id === offer.id) === undefined);
      setMyOffers(inProgressOffers);
    }
  }, [data, loading]);

  function renderProduct(product: Partial<DataProduct>) {
    if (product) {
      const odps = validateOdps(product.openDataProductSpecification);

      if (odps) {
        return (
          <ProductOfferCard
            key={`myproduct-${product.id}`}
            odps={odps}
            product={product}
            productRoute={CatalogProductRoute(product.id)}
          />
        );
      }

      return (
        <Card
          key={`offeringcard-${product.id}`}
          header={(
            <div
              className="hidden pt-0 lg:inline-flex w-full h-36 bg-blue-400 justify-center items-center gap-6 rounded-t-lg lg:text-2xl"
            >
              <CategoriesToIcons categories={['construction']} />
            </div>
          )}
          backgroundColor="white"
          isMini={false}
        >
          <div className="flex w-full">

            <div className="flex-col p-5 bg-blue-400 rounded-tl-lg rounded-bl-lg lg:hidden">
              <div className="flex h-full items-center">
                <div className="flex-col space-y-4 text-xs lg:text-base">
                  <CategoriesToIcons categories={['construction']} />
                </div>
              </div>
            </div>

            <div className="flex-col grow p-3 lg:p-5 justify-between w-full items-center inline-flex">
              <div className="w-full h-5 justify-start items-start gap-2 inline-flex mb-2">
                <Badge key="inprogress" color="purple">In Progress</Badge>
              </div>

              <div className="flex-col justify-start items-start inline-flex w-full">
                <div className="text-left w-full text-gray-900 text-base lg:text-2xl font-bold">
                  Untitled
                </div>
                <div
                  className="basis-0 text-gray-500 text-base font-normal leading-normal"
                >
                  <p className="line-clamp-3 mb-2 text-sm lg:text-base">
                    This offer has not been store to the database yet, please continue setup!
                  </p>
                </div>
              </div>
              <div className="w-full">
                <div className="text-xs text-gray-500">75% Complete</div>
                <Progress progress={75} className="w-full" />
              </div>
              <div className="flex pt-2 justify-between items-center w-full gap-x-4">
                <button
                  onClick={() => deleteInProgressOffer(product.id!)}
                  type="button"
                  className="text-red-700"
                >
                  Delete offer
                </button>
                <Link to={OfferWizardAutoFillOdpsRoute(params.orgId, product.id)}>
                  <InfoButton>
                    <div className="text-xs lg:text-sm font-medium">Back to setup</div>
                    <FontAwesomeIcon
                      icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                      size="lg"
                      className="text-white"
                    />
                  </InfoButton>
                </Link>
              </div>
            </div>
          </div>
        </Card>
      );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100">

      {(!loading) ? (
        <div
          className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 lg:w-full break-inside-avoid-column"
        >

          <div key="new-offer" className="flex bg-white rounded-lg max-w-sm break-inside-avoid">
            <div className="p-4 flex-col space-y-4">
              <FontAwesomeIcon
                size="2x"
                icon={icon({ name: 'thumbs-up', family: 'classic', style: 'regular' })}
              />
              <div className="text-gray-900 text-2xl">
                Get started offering a new dataset!
              </div>
              <div className="text-gray-500 text-base pb-10">
                Your organization was approved by WeCity
              </div>
              <Link target="_blank" to={SelectOfferWizardRoute(params.orgId)}>
                <InfoButton fullSized>
                  <div className="text-xs lg:text-sm font-medium w-full">Start a new offering</div>
                  <FontAwesomeIcon
                    icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                    size="lg"
                    className="text-white"
                  />
                </InfoButton>
              </Link>
            </div>
          </div>
          {(myOffers !== undefined) ? myOffers.map((offer) => (
            <Card
              key={`offeringcard-${offer.id}`}
              header={(
                <div
                  className="hidden pt-0 lg:inline-flex w-full h-36 bg-blue-400 justify-center items-center gap-6 rounded-t-lg lg:text-2xl"
                >
                  <CategoriesToIcons categories={['construction']} />
                </div>
              )}
              backgroundColor="white"
              isMini={false}
            >
              <div className="flex w-full">

                <div className="flex-col p-5 bg-blue-400 rounded-tl-lg rounded-bl-lg lg:hidden">
                  <div className="flex h-full items-center">
                    <div className="flex-col space-y-4 text-xs lg:text-base">
                      <CategoriesToIcons categories={['construction']} />
                    </div>
                  </div>
                </div>

                <div className="flex-col grow p-3 lg:p-5 justify-between w-full items-center inline-flex">
                  <div className="w-full h-5 justify-start items-start gap-2 inline-flex mb-2">
                    <Badge key="inprogress" color="purple">In Progress</Badge>
                  </div>

                  <div className="flex-col justify-start items-start inline-flex w-full">
                    <div className="text-left w-full text-gray-900 text-base lg:text-2xl font-bold">
                      Untitled
                    </div>
                    <div
                      className="basis-0 text-gray-500 text-base font-normal leading-normal"
                    >
                      <p className="line-clamp-3 mb-2 text-sm lg:text-base">
                        This offer has not been store to the database yet, please continue setup!
                      </p>
                    </div>
                  </div>
                  <div className="w-full">
                    {offer.savedToBackend ? (
                      <>
                        <div className="text-xs text-gray-500">75% Complete</div>
                        <Progress progress={75} className="w-full" />
                      </>
                    ) : (
                      <>
                        <div className="text-xs text-gray-500">25% Complete</div>
                        <Progress progress={25} className="w-full" />
                      </>
                    )}
                  </div>
                  <div className="flex pt-2 justify-between items-center w-full gap-x-4">
                    <button
                      onClick={() => deleteInProgressOffer(offer.id)}
                      type="button"
                      className="text-red-700"
                    >
                      Delete offer
                    </button>
                    {offer.savedToBackend ? (
                      <Link to={OfferWizardAutoFillOdpsRoute(params.orgId, offer.id)}>
                        <InfoButton>
                          <div className="text-xs lg:text-sm font-medium">Back to setup</div>
                          <FontAwesomeIcon
                            icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                            size="lg"
                            className="text-white"
                          />
                        </InfoButton>
                      </Link>
                    ) : (
                      <Link to={OfferWizardIsWebserviceRoute(params.orgId, offer.id)}>
                        <InfoButton>
                          <div className="text-xs lg:text-sm font-medium">Back to setup</div>
                          <FontAwesomeIcon
                            icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                            size="lg"
                            className="text-white"
                          />
                        </InfoButton>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          ))
            : ('')}

          {data?.dataMarket.userProducts?.map((product) => (
            renderProduct(product)
          ))}
        </div>
      ) : (
        <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 w-full">
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
        </div>
      )}
    </div>
  );
}

export default MyOfferingsOverview;
