import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { UserSearchQuery } from '../../generated/gql/types';

interface ISearchbarProps {
  submitSearch: (searchString: string) => void
}

function Searchbar({ submitSearch }: ISearchbarProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, setValue } = useForm<UserSearchQuery>();

  const clearQuery = () => {
    setSearchParams(undefined);
    setValue('searchString', '');
  };

  return (
    <form
      className="flex flex-col w-[319px]"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="flex items-center">
        <div className="relative w-full">
          <input
            type="text"
            className="left-0 flex-grow bg-gray-50 border bg-white border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-blue-500 focus:border-blue-500 w-full"
            placeholder="Zoek producten"
            {...register('searchString', {
              required: true,
              onChange: (e) => {
                if (!e.target.value) clearQuery();
                else submitSearch(e.target.value);
              },
              value: searchParams.get('searchString') ?? '',
            })}
          />
          <button
            type="button"
            onClick={clearQuery}
            className="right-0 absolute rounded-l-md text-sm font-medium text-gray-500 h-full px-3"
          >
            <FontAwesomeIcon icon={icon({ name: 'xmark', family: 'classic', style: 'regular' })} color="gray" />
          </button>
        </div>
        <button type="button" className="bg-cyan-700 h-full w-10 rounded-r-lg">
          <FontAwesomeIcon icon={icon({ name: 'search', family: 'classic', style: 'regular' })} color="white" />
        </button>
      </div>
    </form>
  );
}

export default Searchbar;
