export const WeCityOrganizationIdHeader = 'wecity-organization-id';
export const OfferingsLocalStorageKey = 'wecity-offerings';

export type ProductVisibility = { name: string, translationKey: string };
export const productVisibilities = [
  {
    name: 'Public',
    translationKey: 'product_visibility_public',
  },
  {
    name: 'Organisation',
    translationKey: 'product_visibility_organization',
  },
  {
    name: 'Private',
    translationKey: 'product_visibility_private',
  },
  {
    name: 'DMI only',
    translationKey: 'product_visibility_dmiOnly',
  },
];

export type ProductType = { name: string, icon?: string, imagePath?: string };
export const productTypes: ProductType[] = [
  { name: 'Dataproduct' },
  { name: 'Consultancy' },
  { name: 'Applicatie' },
  { name: 'Hardware' },
];

const Global = {
  productVisibilities,
};
export default Global;
